import React from "react";

function TechExpertise() {
  return (
    <section className="section section--technologies --bg-gray-lightest --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">
              Technologies we are experts at
            </h2>
          </div>
          <div className="container__col-md-6">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/magento.png"
                alt="Magento Open Source"
              />
              <h5 className="card__title">Magento Open Source</h5>
              <div className="card__description">
                Delivers all the basic eCommerce capabilities to build a unique
                online store from the ground up.
              </div>
              <a
                className="card__action --action-link"
                href="/adobe-commerce-magento"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="container__col-md-6">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/adobe-commerce.png"
                alt="Adobe Commerce"
              />
              <h5 className="card__title">Adobe Commerce</h5>
              <div className="card__description">
                From catalog to payment to fulfillment, Adobe Commerce offers
                future-proof technology.
              </div>
              <a
                className="card__action --action-link"
                href="/adobe-commerce-magento"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechExpertise;
