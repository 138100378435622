import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import ECommerce from "../components/Solutions/E-Commerce/E-Commerce";
import Blog from "../components/Solutions/Blog/Blog";
import TechExpertise from "../components/Solutions/TechExpertise_E-Commerce/TechExpertise";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import { Helmet } from "react-helmet";
function solutionsECommerce() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout><Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies - E-Commerce
       </title>
       <meta
         name="description"
         content="Enterprise or Small Business,
         break the boundary with
         branded omnichannel eCommerce."
       ></meta></Helmet>
      <ECommerce />
      <SolutionsHelmet />
      <Blog />
      <TechExpertise />
      <LearnAboutUs page="e-commerce" />
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default solutionsECommerce;
