import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function ECommerce() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner section--gray-bg">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading">E-Commerce </h1>
            <h2 className="container__sub-heading">
              Enterprise or Small Business, <br />
              break the boundary with <br />
              <span className="--red-color">branded omnichannel eCommerce</span>
            </h2>
            <h6 className="container__content">
              Gain control and build loyalty with personalized eCommerce
              experience. Choose between our open source and cloud-hosted
              offerings to build a branded{" "}
              <span className="--red-color">
                commerce experience that is affordable, flexible, extensible,
                and scalable.
              </span>{" "}
            </h6>
            <button className="container__button" onClick={openModal}>
              LET’S TALK
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img"
              src="../../solutions/solutions/e-commerce.png"
              alt="E-Commerce"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="ECommerce-Banner"
        page="ECommerce"
       
      />
    </section>
  );
}

export default ECommerce;
